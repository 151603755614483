import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router'
import moment from 'moment'
import {toast} from 'react-toastify'
import styles from './style.module.scss'

import InputDisabled from '../../components/InputDisabled'
import Button from '../../components/Button'
import WorkerModel from '../../models/Worker'
import WorkerService from '../../services/rest/WorkerService'

function Worker() {
  const [worker, setWorker] = useState<WorkerModel | undefined>()
  const {workerID} = useParams()

  useEffect(() => {
    if (!worker) {
      const id = workerID ? parseInt(workerID, 10) : 0
      new WorkerService()
        .getOne(id)
        .then(response => {
          if (response.status) {
            setWorker(response.data)
          }
        })
        .catch(error => {
          toast.warning(error.data.message)
        })
    }
  })

  return (
    <div className={styles.confirmation}>
      <h1>
        Telefone {worker ? worker.login : ''} já se encontra vinculado a uma
        conta na iWof
      </h1>
      <h3>Confira os dados abaixo e confirma para adicionar selos.</h3>
      <form action="" className={styles.form}>
        <InputDisabled
          id="name"
          name="name"
          label="NOME COMPLETO"
          value={worker ? worker.name : ''}
        />
        <InputDisabled
          id="phone"
          name="phone"
          label="TELEFONE"
          value={worker ? worker.login : ''}
        />
        <InputDisabled
          id="email"
          name="email"
          label="E-MAIL"
          value={worker ? worker.email : ''}
        />
        <InputDisabled
          id="cpf"
          name="cpf"
          label="CPF"
          value={worker ? worker.cpf : ''}
        />
        <InputDisabled
          id="birthday"
          name="birthday"
          label="DATA DE NASCIMENTO"
          value={worker ? moment(worker.birthdate).format('DD/MM/Y') : ''}
        />
      </form>
      <div className="center">
        <Button
          label="Continuar"
          id="confirmation"
          type="link"
          link={`/certificates/${workerID}`}
        />
      </div>
    </div>
  )
}

export default Worker
