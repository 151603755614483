import React, {useState, useEffect, FormEvent, useReducer} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faTags,
  faEllipsisV,
  faSortAlphaDown,
  faSortAlphaUp,
  faFilter,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import styles from './style.module.scss'

import Badge from '../../components/Badge'
import DropdownButton from '../../components/DropdownButton'
import Input from '../../components/Input'
import Button from '../../components/Button'
import RegisterService from '../../services/rest/RegisterService'

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  }
}

function Workers() {
  const limit = 10

  const [data, setData] = useState<any[]>()
  const [actualPage, setActualPage] = useState<number | any>(1)
  const [prevPage, setPrevPage] = useState<number | any>(1)
  const [nextPage, setNextPage] = useState<number | any>(1)

  const [to, setTo] = useState<number | any>(10)
  const [from, setFrom] = useState<number | any>(10)
  const [total, setTotal] = useState(0)
  const [searchData, setSearchData] = useReducer(formReducer, {})

  const [order, setOrder] = useState('ASC')
  const [filterOpen, setFilterOpen] = useState(false)

  async function list(page = 1, sort = 'ASC') {
    new RegisterService('workers')
      .listAll(page, limit, searchData, sort)
      .then(response => {
        if (response.status) {
          const preRegisters = (response.data as any).data
          setData(preRegisters)

          setActualPage((response.data as any).current_page)
          setPrevPage((response.data as any).prev_page)
          setNextPage((response.data as any).next_page)
          setTotal((response.data as any).total)
          setTo((response.data as any).to)
          setFrom((response.data as any).from)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  async function handleSubmit(event: FormEvent): Promise<void> {
    event.preventDefault()
    list(actualPage)
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target

    if (event.target.name === 'created_begin') {
      if (event.target.value.length === 10) {
        const newDate = event.target.value.split('/')
        const formated = new Date(
          Number(newDate[2]),
          Number(newDate[1]) - 1,
          Number(newDate[0]),
        )
        setSearchData({
          name: 'created_at_begin',
          value: moment(formated).format('YYYY-MM-DD'),
        })
      }
      setSearchData({
        name: event.target.name,
        value: event.target.value,
      })
    } else if (event.target.name === 'created_end') {
      const newDate = event.target.value.split('/')
      const formated = new Date(
        Number(newDate[2]),
        Number(newDate[1]) - 1,
        Number(newDate[0]),
      )
      setSearchData({
        name: 'created_at_end',
        value: moment(formated).format('YYYY-MM-DD'),
      })
      setSearchData({
        name: event.target.name,
        value: event.target.value,
      })
    }
    setSearchData({
      name: event.target.name,
      value,
    })
  }
  const changeOrder = () => {
    if (order === 'ASC') {
      setOrder('DESC')
      list(actualPage, 'DESC')
    } else {
      setOrder('ASC')
      list(actualPage, 'ASC')
    }
  }
  const next = () => {
    if (nextPage && nextPage !== actualPage) list(nextPage)
  }
  const prev = () => {
    if (prevPage && prevPage !== actualPage) list(prevPage)
  }
  useEffect(() => {
    if (data === undefined) {
      list(actualPage)
    }
  })
  return (
    <div className={styles.workers}>
      <div className={styles.right}>
        <button type="button" onMouseDown={() => changeOrder()}>
          <FontAwesomeIcon
            icon={order === 'ASC' ? faSortAlphaDown : faSortAlphaUp}
          />
        </button>
        <button type="button" onMouseDown={() => setFilterOpen(!filterOpen)}>
          <FontAwesomeIcon icon={faFilter} />
        </button>
      </div>
      <h1>Todos os cadastros</h1>

      <div className={styles.searchForm}>
        <form
          action=""
          onSubmit={handleSubmit}
          className={filterOpen ? styles.active : ''}
        >
          <Input
            label=""
            placeholder="Nome"
            id="name"
            name="name"
            type="text"
            onInput={handleChange}
            value={searchData.name || ''}
          />
          <Input
            label=""
            placeholder="Cadastrado a partir"
            id="created_begin"
            name="created_begin"
            type="text"
            mask="99/99/9999"
            onInput={handleChange}
            value={searchData.created_begin || ''}
          />
          <Input
            label=""
            placeholder="Cadastrado até"
            id="created_end"
            name="created_end"
            type="text"
            mask="99/99/9999"
            onInput={handleChange}
            value={searchData.created_end || ''}
          />
          <Button label="Pesquisar" id="search" type="submit" />
        </form>
      </div>

      <div className={styles.list}>
        <table className={styles.table}>
          <thead className={styles.head}>
            <tr>
              <th>Nome</th>
              <th>Selos</th>
              <th>Cadastrado em</th>
              <th>Status</th>
              <th aria-label="Opções" />
            </tr>
          </thead>
          <tbody className={styles.body}>
            {data
              ? data.map(val => (
                  <tr key={val.id}>
                    <td>{val.name}</td>
                    <td>
                      {val.certificates.map(value => (
                        <li key={value.id}>{value.name}</li>
                      ))}
                    </td>
                    <td>{moment(val.createdAt).format('DD/MM/Y')}</td>
                    <td>
                      {val.registration_date ? (
                        <Badge title="Aprovado" color="success" />
                      ) : (
                        <Badge title="Pendente" color="danger" />
                      )}
                    </td>
                    <td>
                      <DropdownButton
                        title={<FontAwesomeIcon icon={faEllipsisV} />}
                      >
                        <a href={`/certificates/${val.id}/pre`}>
                          <FontAwesomeIcon icon={faTags} /> Selos
                        </a>
                      </DropdownButton>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
      <div className={styles.pagination}>
        <span className={styles.left}>Total de {total} cadastros</span>
        <div>
          <span className={styles.pages}>
            Exibindo {from} de {to} resultados
          </span>
          <button
            type="button"
            className={styles.arrows}
            onClick={() => prev()}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            type="button"
            className={styles.arrows}
            onClick={() => next()}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Workers
