import jwt from 'jwt-simple'

export const USER_DATA = 'web_institution_iwof_administrators_data'
export const TOKEN_KEY = 'web_institution_iwof_token_key_api'
export const REFRESH_KEY = 'web_institution_iwof_refresh_key_api'

export const setUserData = userData => localStorage.setItem(USER_DATA, userData)

export const logoutAction = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem(REFRESH_KEY)
  window.location.href = `/login`
}

export const setToken = accessToken =>
  localStorage.setItem(TOKEN_KEY, accessToken)

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY)
  if (token) return localStorage.getItem(TOKEN_KEY)

  logoutAction()
  return false
}

export const getUserData = () => {
  const token = getToken()
  let privateKey: string
  if (process.env.REACT_APP_PRIVATE_KEY) {
    privateKey = process.env.REACT_APP_PRIVATE_KEY
  } else {
    throw new Error('PRIVATE KEY variable is not set')
  }

  if (token) {
    try {
      const decodedToken = jwt.decode(token, privateKey)
      return decodedToken
    } catch (error) {
      logoutAction()
    }
  }
  return false
}
