import {AxiosError} from 'axios'
import InstituitionModel from '../../models/Instituition'
import BaseService from './BaseService'
import LoginInterceptor from '../../utils/LoginUtils/LoginInterceptor'
import IServiceResponse from '../interface/IServiceResponse'

export default class InstitutionService extends BaseService<InstituitionModel> {
  constructor(pathname = 'institutions') {
    const loginInterceptor = new LoginInterceptor()
    super(pathname, loginInterceptor.requestInterceptor.bind(loginInterceptor))
  }

  public async changePassword(
    password: string,
  ): Promise<IServiceResponse<string>> {
    try {
      const response = await this.connection.post('change_password/', {
        password,
      })

      return {
        response,
        data: response?.data,
        status: true,
      }
    } catch (error) {
      const axiosError = error as AxiosError
      const errorResponse = {
        response: axiosError.response,
        data: axiosError.response?.data,
        status: false,
      }
      throw errorResponse
    }
  }
}
