import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './styles/global.scss'

import Header from './components/Header'
import Sidebar from './components/Sidebar'

import Certificates from './views/Certificates'
import Config from './views/Config'
import Worker from './views/Worker'
import Dashboard from './views/Dashboard'
import Finished from './views/Finished'
import Login from './views/Login/index'
import Register from './views/Register'
import Workers from './views/Workers'
import NotFound from './views/Default/404'

function Application() {
  return (
    <div>
      <ToastContainer theme="colored" autoClose={3000} />
      {window.location.pathname === '/login' ? (
        <div className="container">
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
            </Routes>
          </BrowserRouter>
        </div>
      ) : (
        <div>
          <BrowserRouter>
            <Header />
            <div className="container">
              <Sidebar />
              <div className="content">
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="certificates">
                    <Route path="/certificates">
                      <Route
                        path=":workerID/:pageID"
                        element={<Certificates />}
                      />
                      <Route path=":workerID" element={<Certificates />} />
                    </Route>
                    <Route path="" element={<Certificates />} />
                  </Route>
                  <Route path="/worker/:workerID" element={<Worker />} />
                  <Route path="/config" element={<Config />} />
                  <Route path="/finished" element={<Finished />} />

                  <Route path="/register" element={<Register />} />
                  <Route path="/workers" element={<Workers />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </div>
            </div>
          </BrowserRouter>
        </div>
      )}
    </div>
  )
}

export default Application
