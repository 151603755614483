import React from 'react'
import styles from './style.module.scss'
import image from '../../../assets/404.svg'

function NotFound() {
  return (
    <div className={styles.notFound}>
      <h1>Página não encontrada</h1>
      <div className={styles.center}>
        <img src={image} alt="Not Found" />
        <p>A página que você tentou acessar não existe</p>
      </div>
    </div>
  )
}

export default NotFound
