import React, {useState} from 'react'
import {toast} from 'react-toastify'

import styles from './style.module.scss'
import Input from '../../components/Input'
import InputDisabled from '../../components/InputDisabled'
import Button from '../../components/Button'
import {getUserData} from '../../services/auth.services'
import InstitutionService from '../../services/rest/InstitutionService'

function config() {
  const user = getUserData()

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const changePassword = () => {
    if (newPassword && confirmPassword) {
      if (newPassword !== confirmPassword) {
        toast.error('As senhas não conferem')
      } else {
        new InstitutionService()
          .changePassword(newPassword)
          .then(response => {
            if (response.status) toast.success('Senha alterada com sucesso')
          })
          .catch(error => {
            toast.error('Não foi possivel alterar a senha no momento')
            console.log(error)
          })
      }
    } else {
      toast.warning('Preencha uma nova senha')
    }
  }
  return (
    <div className={styles.config}>
      <h1>Editar meu perfil</h1>

      <form action="">
        <InputDisabled
          id="email"
          name="email"
          label="EMAIL"
          value={user.email}
        />
        <Input
          id="newPassword"
          name="newPassword"
          value={newPassword}
          onInput={e => setNewPassword(e.currentTarget.value)}
          label="NOVA SENHA"
        />
        <Input
          id="newPasswordConfirm"
          name="newPasswordConfirm"
          value={confirmPassword}
          onInput={e => setConfirmPassword(e.currentTarget.value)}
          label="CONFIRME NOVA SENHA"
        />
        <Button
          label="Salvar"
          id="confirmation"
          type="button"
          click={() => changePassword()}
        />
      </form>
    </div>
  )
}

export default config
