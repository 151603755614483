import {AxiosRequestConfig} from 'axios'
import {TOKEN_KEY} from '../../services/rest/LoginService'

export default class LoginInterceptor {
  public requestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
    const token = localStorage.getItem(TOKEN_KEY)

    config.headers = {
      Authorization: `Bearer ${token}`,
    }

    return config
  }
}
