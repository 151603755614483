import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {toast} from 'react-toastify'
import styles from './style.module.scss'

import Card from '../../components/Card/index'
import Input from '../../components/Input'
import Button from '../../components/Button'

import DashboardService from '../../services/rest/DashboardService'
import DashboardModel from '../../models/Dashboard'
import WorkerService from '../../services/rest/WorkerService'

function Dashboard() {
  const navigate = useNavigate()
  const [input, setInput] = useState('')
  const [data, setData] = useState<DashboardModel | undefined>()

  const action = async () => {
    new WorkerService('search_worker')
      .search(input)
      .then(response => {
        if (response !== undefined && response.status) {
          toast.success('Usuário encontrado!')
          setTimeout(() => {
            navigate(`/worker/${response.data?.id}`)
          }, 3000)
        }
      })
      .catch(error => {
        toast.warning(error.data.message)
        setTimeout(() => {
          navigate(`/register`)
        }, 3000)
      })
  }

  useEffect(() => {
    if (!data) {
      new DashboardService()
        .getOne()
        .then(response => {
          if (response.status) {
            setData(response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  })

  return (
    <div className={styles.dashboard}>
      <div className={styles.listCards}>
        <Card title="Cadastro totais" value={data ? data.total : 0} />
        <Card title="Cadastro aceitos" value={data ? data.completed : 0} />
        <Card title="Cadastro Pendentes" value={data ? data.pending : 0} />
        <Card title="Selos Distribuidos" value={data ? data.certificates : 0} />
      </div>
      <div className={styles.search}>
        <h4>
          Informe o telefone do aluno para realizar o pré-cadastro na plataforma
          iWof:
        </h4>
        <Input
          id="search"
          name="search"
          label=""
          value={input}
          onInput={e => setInput(e.currentTarget.value)}
          placeholder="Digite DDD + telefone"
          mask="(99) 99999-9999"
        />
        <Button
          label="Continuar"
          id="search"
          type="button"
          click={action}
          link="/confirmation/1"
        />
      </div>
    </div>
  )
}
export default Dashboard
