import React, {useState} from 'react'
import {useNavigate} from 'react-router'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import {toast} from 'react-toastify'
import LoginService from '../../services/rest/LoginService'

import styles from './style.module.scss'
import logo from '../../assets/logo.png'

import Input from '../../components/Input'
import Button from '../../components/Button'

function login() {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [loginInput, setLoginInput] = useState('')
  const [passwordInput, setPasswordInput] = useState('')

  function validFields(username, password) {
    /* eslint-disable no-useless-escape */
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!re.test(username)) {
      toast.error('Digite um email valido')
      return false
    }
    if (password.length === 0) {
      toast.error('Digite sua senha')
      return false
    }

    return true
  }

  function makeLogin() {
    if (validFields(loginInput, passwordInput)) {
      new LoginService()
        .getToken(loginInput, passwordInput)
        .then(response => {
          if (response.status) {
            toast.success('Seja Bem Vindo!')
            window.location.href = '/'
          }
        })
        .catch(error => {
          if (!error.status) {
            toast.error('O usuário e senha não combinam')
          }
        })
    }
  }

  return (
    <div className={styles.login}>
      <div className={styles.form}>
        <img src={logo} className={styles.logo} alt="logo" />
        <h1>Faça login na área de parceiros:</h1>
        <form action="">
          <Input
            label="Email"
            id="email"
            name="email"
            type="email"
            value={loginInput}
            onInput={e => setLoginInput(e.currentTarget.value)}
          />
          <Input
            label="Senha"
            id="password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={passwordInput}
            onInput={e => setPasswordInput(e.currentTarget.value)}
            after={
              <button
                type="button"
                className={styles.showPassword}
                onClick={() => setShowPassword(!showPassword)}
              >
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </button>
            }
          />
          <Button
            label="Continuar"
            id="login"
            type="button"
            click={() => makeLogin()}
          />
        </form>
      </div>
    </div>
  )
}

export default login
