import StateModel from '../../models/State'
import BaseService from './BaseService'
import LoginInterceptor from '../../utils/LoginUtils/LoginInterceptor'

export default class StateService<T> extends BaseService<StateModel> {
  constructor(pathname = 'states') {
    const loginInterceptor = new LoginInterceptor()
    super(pathname, loginInterceptor.requestInterceptor.bind(loginInterceptor))
  }
}
