import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'

export default class ConnectionProvider {
  protected connection: AxiosInstance

  constructor(
    endpointPrefix: string,
    requestInterceptor = (config: AxiosRequestConfig) => config,
    reponseInterceptor = (config: AxiosResponse) => config,
  ) {
    this.connection = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}${endpointPrefix}`,
    })
    this.setRequestInterceptor(requestInterceptor)
    this.setResponseInterceptor(reponseInterceptor)
  }

  private setRequestInterceptor(
    requestInterceptor: (config: AxiosRequestConfig) => AxiosRequestConfig,
  ) {
    this.connection.interceptors.request.use(requestInterceptor)
  }

  private setResponseInterceptor(
    responseInterceptor: (config: AxiosResponse) => AxiosResponse,
  ) {
    this.connection.interceptors.response.use(responseInterceptor)
  }
}
