import React, {useState, useEffect, useReducer, FormEvent} from 'react'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import moment from 'moment'
import styles from './style.module.scss'

import Input from '../../components/Input'
import Button from '../../components/Button'
import StateService from '../../services/rest/StateService'
import RegisterService from '../../services/rest/RegisterService'

interface optionType {
  key: number
  value: string
}

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  }
}

function Register() {
  const navigate = useNavigate()
  const [states, setStates] = useState<optionType[] | undefined>()
  const [formData, setFormData] = useReducer(formReducer, {})

  async function handleSubmit(event: FormEvent): Promise<void> {
    event.preventDefault()
    try {
      const res = await new RegisterService().register(formData)
      toast.success('Pré-cadastro concluido com sucesso')
      setTimeout(() => {
        navigate(`/certificates/${(res.data as any).identifiers[0].id}/pre`)
      }, 3000)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = event => {
    const value =
      event.target.name === 'state_id'
        ? Number(event.target.value)
        : event.target.value

    if (event.target.name === 'birthdayBR') {
      if (event.target.value.length === 10) {
        const newDate = event.target.value.split('/')
        const formated = new Date(newDate[2], newDate[1] - 1, newDate[0])
        setFormData({
          name: 'birthdate',
          value: moment(formated).format('YYYY-MM-DD'),
        })
      }
      setFormData({
        name: event.target.name,
        value: event.target.value,
      })
    }

    setFormData({
      name: event.target.name,
      value,
    })
  }

  function getStates(values) {
    return values.map(val => {
      return {key: val.id, value: val.name}
    })
  }

  useEffect(() => {
    if (!states) {
      new StateService()
        .getAll()
        .then(response => {
          if (response.status) {
            setStates(getStates(response.data))
          }
        })
        .catch(error => {
          console.log(error)
          toast.error('Houve um erro ao salvar os dados')
        })
    }
  })

  return (
    <div className={styles.register}>
      <h2>Pré-cadastro iWof</h2>

      <form action="" onSubmit={handleSubmit}>
        <Input
          id="name"
          name="name"
          label="NOME COMPLETO"
          onInput={handleChange}
          value={formData.name || ''}
        />
        <Input
          id="phone"
          name="phone"
          label="TELEFONE"
          placeholder="Digite DDD + telefone"
          mask="(99) 9 9999-9999"
          value={formData.phone || ''}
          onInput={handleChange}
        />
        <Input
          id="email"
          name="email"
          label="E-MAIL"
          type="email"
          value={formData.email || ''}
          onInput={handleChange}
        />
        <Input
          id="cpf"
          name="cpf"
          mask="999.999.999-99"
          label="CPF"
          value={formData.cpf || ''}
          onInput={handleChange}
        />
        <Input
          id="birthdayBR"
          name="birthdayBR"
          mask="99/99/9999"
          label="DATA DE NASCIMENTO"
          value={formData.birthdayBR || ''}
          onInput={handleChange}
        />
        <Input
          id="cep"
          name="cep"
          label="CEP"
          mask="99.999-999"
          value={formData.cep || ''}
          onInput={handleChange}
        />
        <Input
          id="state"
          name="state_id"
          options={states}
          type="select"
          label="ESTADO"
          value={formData.state_id || ''}
          onSelect={handleChange}
        />
        <Input
          id="city"
          name="city"
          label="CIDADE"
          value={formData.city || ''}
          onInput={handleChange}
        />
        <Input
          id="district"
          name="district"
          label="BAIRRO"
          value={formData.district || ''}
          onInput={handleChange}
        />
        <Input
          id="street"
          name="street"
          label="RUA"
          value={formData.street || ''}
          onInput={handleChange}
        />
        <Input
          id="number"
          name="number"
          label="NÚMERO"
          value={formData.number || ''}
          onInput={handleChange}
        />
        <Input
          id="complement"
          name="complement"
          label="COMPLEMENTO"
          value={formData.complement || ''}
          onInput={handleChange}
        />
        <Button label="Continuar" id="save" type="submit" />
      </form>
    </div>
  )
}
export default Register
