import {AxiosError} from 'axios'
import WorkerModel from '../../models/Worker'
import BaseService from './BaseService'
import LoginInterceptor from '../../utils/LoginUtils/LoginInterceptor'
import IServiceResponse from '../interface/IServiceResponse'

export default class WorkerService<T> extends BaseService<WorkerModel> {
  constructor(pathname = 'workers') {
    const loginInterceptor = new LoginInterceptor()
    super(pathname, loginInterceptor.requestInterceptor.bind(loginInterceptor))
  }

  public async storeCertificates(
    id: number,
    certificates?: number[],
  ): Promise<IServiceResponse<T>> {
    try {
      const response = await this.connection.post<T>(
        `${this.endpointName}/${id}/certificates`,
        {
          certificates,
        },
      )
      return {
        status: true,
        data: response.data,
        response,
      }
    } catch (error) {
      const axiosError = error as AxiosError
      const errorResponse = {
        response: axiosError.response,
        data: axiosError.response?.data,
        status: false,
      }
      this.isLogged(axiosError.response?.status)
      throw errorResponse
    }
  }
}
