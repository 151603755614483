import React, {useState} from 'react'
import styles from './style.module.scss'

type DropdownButtonProps = {
  title: string | JSX.Element
  children: JSX.Element | JSX.Element[]
}

function DropdownButton({title, children}: DropdownButtonProps) {
  const [collapsed, setCollapsed] = useState(true)

  const buttonAction = () => {
    setCollapsed(!collapsed)
  }
  return (
    <div
      className={
        collapsed ? styles.dropDownButtonCollapsed : styles.dropDownButton
      }
      onClick={() => buttonAction()}
      onKeyDown={() => buttonAction()}
      role="button"
      tabIndex={0}
    >
      <span>{title}</span>
      <div className={styles.options}>{children}</div>
    </div>
  )
}

export default DropdownButton
