import React from 'react'
import styles from './style.module.scss'

type InputDisabledProps = {
  id: string
  name: string
  label: string
  value: string
}
function InputDisabled({label, id, name, value}: InputDisabledProps) {
  return (
    <label htmlFor={id} className={styles.label}>
      {label}
      <input
        name={name}
        className={styles.input}
        defaultValue={value}
        disabled
        readOnly
      />
    </label>
  )
}

export default InputDisabled
