import React from 'react'
import styles from './style.module.scss'

type CardProps = {
  title: string
  value: string | number
}
function Card({title, value}: CardProps) {
  return (
    <div className={styles.card}>
      <h2>{title}</h2>
      <h1>{value}</h1>
    </div>
  )
}

export default Card
