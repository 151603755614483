import React from 'react'
import InputMask from 'react-input-mask'

import styles from './style.module.scss'

interface optionType {
  key: number
  value: string
}

type InputProps = {
  id: string
  name: string
  label: string
  value?: string
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSelect?: (event: React.FormEvent<HTMLSelectElement>) => void
  type?: string
  placeholder?: string
  before?: JSX.Element
  after?: JSX.Element
  mask?: string
  options?: optionType[]
}

function Input({
  label,
  id,
  name,
  type,
  placeholder,
  before,
  after,
  mask,
  options,
  value,
  onInput,
  onSelect,
}: InputProps) {
  if (type === 'select') {
    return (
      <label htmlFor={id} className={styles.label}>
        {label}
        <div className={styles.wrapper}>
          <select
            id={id}
            name={name}
            className={styles.input}
            onChange={onSelect}
            value={value}
          >
            {options?.map(item => (
              <option key={item?.key} value={item?.key}>
                {item?.value}
              </option>
            ))}
          </select>
        </div>
      </label>
    )
  }
  return (
    <label htmlFor={id} className={styles.label}>
      {label}
      {before}
      {mask === '' ? (
        <input
          id={id}
          className={styles.input}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onInput}
        />
      ) : (
        <InputMask
          mask={mask}
          maskChar=""
          name={name}
          placeholder={placeholder}
          className={styles.input}
          value={value}
          onChange={onInput}
        />
      )}

      {after}
    </label>
  )
}

Input.defaultProps = {
  placeholder: '',
  mask: '',
  type: 'text',
  value: '',
  onInput: null,
  before: null,
  after: null,
  options: null,
  onSelect: null,
}
export default Input
