import React from 'react'
import styles from './style.module.scss'

type ButtonProps = {
  id: string
  label: string
  type?: string
  link?: string
  click?: () => void
}

function Button({id, label, type, link, click}: ButtonProps) {
  if (type === 'link') {
    return (
      <a href={link} id={id} className={styles.button}>
        {label}
      </a>
    )
  }
  if (type === 'submit') {
    return (
      <button id={id} type="submit" className={styles.button}>
        {label}
      </button>
    )
  }

  return (
    <button
      id={id}
      onKeyDown={click}
      type="button"
      className={styles.button}
      onClick={click}
    >
      {label}
    </button>
  )
}

Button.defaultProps = {
  type: 'button',
  link: '',
  click: null,
}
export default Button
