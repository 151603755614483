import {AxiosError} from 'axios'
import ConnectionProvider from './ConnectionProvider'
import IServiceResponse from '../interface/IServiceResponse'

export const USER_DATA = 'web_institution_iwof_administrators_data'
export const TOKEN_KEY = 'web_institution_iwof_token_key_api'
export const REFRESH_KEY = 'web_institution_iwof_refresh_key_api'

export default class LoginService extends ConnectionProvider {
  constructor() {
    super('/api/v1')
  }

  public async getToken(
    username: string,
    password: string,
  ): Promise<IServiceResponse<string>> {
    try {
      const response = await this.connection.post('login/', {
        username,
        password,
      })

      if (response.status && response.data) {
        localStorage.setItem(TOKEN_KEY, response.data.access)
        localStorage.setItem(REFRESH_KEY, response.data.refresh)
      }

      return {
        response,
        data: response?.data,
        status: true,
      }
    } catch (error) {
      const axiosError = error as AxiosError
      const errorResponse = {
        response: axiosError.response,
        data: axiosError.response?.data,
        status: false,
      }
      throw errorResponse
    }
  }
}
