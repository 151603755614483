import {AxiosError} from 'axios'
import BaseService from './BaseService'
import LoginInterceptor from '../../utils/LoginUtils/LoginInterceptor'
import IServiceResponse from '../interface/IServiceResponse'
import RegisterModel from '../../models/Register'

export default class RegisterService<T> extends BaseService<RegisterModel> {
  constructor(pathname = 'register') {
    const loginInterceptor = new LoginInterceptor()
    super(pathname, loginInterceptor.requestInterceptor.bind(loginInterceptor))
  }

  public async register(worker?: T[]): Promise<IServiceResponse<T>> {
    try {
      const response = await this.connection.post<T>(
        `${this.endpointName}`,
        worker,
      )
      return {
        status: true,
        data: response.data,
        response,
      }
    } catch (error) {
      const axiosError = error as AxiosError
      const errorResponse = {
        response: axiosError.response,
        data: axiosError.response?.data,
        status: false,
      }
      this.isLogged(axiosError.response?.status)
      throw errorResponse
    }
  }

  public async storeCertificates(
    id: number,
    certificates?: number[],
  ): Promise<IServiceResponse<T>> {
    try {
      const response = await this.connection.put<T>(
        `${this.endpointName}/${id}/certificates`,
        {
          certificates,
        },
      )
      return {
        status: true,
        data: response.data,
        response,
      }
    } catch (error) {
      const axiosError = error as AxiosError
      const errorResponse = {
        response: axiosError.response,
        data: axiosError.response?.data,
        status: false,
      }
      this.isLogged(axiosError.response?.status)
      throw errorResponse
    }
  }
}
