import CertificateModel from '../../models/Certificate'
import BaseService from './BaseService'
import LoginInterceptor from '../../utils/LoginUtils/LoginInterceptor'

export default class CertificateService extends BaseService<CertificateModel> {
  constructor(pathname = 'certificates') {
    const loginInterceptor = new LoginInterceptor()
    super(pathname, loginInterceptor.requestInterceptor.bind(loginInterceptor))
  }
}
