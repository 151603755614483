import React, {useEffect} from 'react'
import {useNavigate} from 'react-router'
import {toast} from 'react-toastify'
import styles from './style.module.scss'
import image from '../../assets/waiting.svg'

function Finished() {
  const navigate = useNavigate()
  useEffect(() => {
    toast.success('Selos inseridos com sucesso')
    setTimeout(() => {
      navigate(`/`)
    }, 3000)
  })
  return (
    <div className={styles.finished}>
      <h1>Pré-cadastro realizado com sucesso </h1>
      <div className={styles.centerFinished}>
        <img src={image} alt="Cadastro finalizado" />
        <p>
          Agora, informe ao aluno que é necessário baixar o aplicativo e
          completar o cadastro.
        </p>
      </div>
    </div>
  )
}

export default Finished
