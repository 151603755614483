import DashboardModel from '../../models/Dashboard'
import BaseService from './BaseService'
import LoginInterceptor from '../../utils/LoginUtils/LoginInterceptor'

export default class DashboardService extends BaseService<DashboardModel> {
  constructor() {
    const loginInterceptor = new LoginInterceptor()
    super(
      'dashboard',
      loginInterceptor.requestInterceptor.bind(loginInterceptor),
    )
  }
}
