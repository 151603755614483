import React from 'react'
import {useNavigate} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faSignOutAlt} from '@fortawesome/free-solid-svg-icons'

import styles from './style.module.scss'
import logo from '../../assets/logo.png'

import DropdownButton from '../DropdownButton'
import {getUserData, logoutAction} from '../../services/auth.services'

function header() {
  const navigate = useNavigate()
  const user = getUserData()
  return (
    <div className={styles.header}>
      <div className={styles.home}>
        <img src={logo} className="logo" alt="logo" />
      </div>
      <h1>Área do Parceiro</h1>
      <div className={styles.profile}>
        <DropdownButton title={user ? user.email : ''}>
          <a
            href="/config"
            onClick={e => {
              e.preventDefault()
              navigate('/config')
            }}
          >
            <FontAwesomeIcon icon={faEdit} /> Editar Perfil
          </a>
          <button
            type="button"
            onClick={e => {
              e.preventDefault()
              logoutAction()
            }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
            Logout
          </button>
        </DropdownButton>
      </div>
    </div>
  )
}

export default header
