import React from 'react'
import {useNavigate} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faChartPie,
  faUsers,
  faCog,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons'

import styles from './style.module.scss'
import {logoutAction} from '../../services/auth.services'

function sidebar() {
  const navigate = useNavigate()

  return (
    <div className={styles.sidebar}>
      <nav>
        <ul>
          <li>
            <a
              href="/"
              onClick={e => {
                e.preventDefault()
                navigate('/')
              }}
              className={window.location.pathname === '/' ? styles.active : ''}
            >
              <FontAwesomeIcon icon={faChartPie} />
              <span>Início</span>
            </a>
          </li>
          <li>
            <a
              href="/workers"
              onClick={e => {
                e.preventDefault()
                navigate('/workers')
              }}
              className={
                window.location.pathname === '/workers' ? styles.active : ''
              }
            >
              <FontAwesomeIcon icon={faUsers} />
              <span>Cadastros</span>
            </a>
          </li>
          <li>
            <a
              href="/config"
              onClick={e => {
                e.preventDefault()
                navigate('/config')
              }}
              className={
                window.location.pathname === '/config' ? styles.active : ''
              }
            >
              <FontAwesomeIcon icon={faCog} />
              <span>Configurações</span>
            </a>
          </li>
          <li>
            <a
              href="/login"
              onClick={e => {
                e.preventDefault()
                logoutAction()
              }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} />
              <span>Sair</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default sidebar
