import React, {useState, useEffect} from 'react'
import {useParams, useNavigate} from 'react-router'
import {toast} from 'react-toastify'

import styles from './style.module.scss'
import CertificateService from '../../services/rest/CertificateService'
import CertificateModel from '../../models/Certificate'

import Button from '../../components/Button'

import WorkerService from '../../services/rest/WorkerService'
import WorkerModel from '../../models/Worker'
import RegisterModel from '../../models/Register'
import RegisterService from '../../services/rest/RegisterService'

function Certificates() {
  const navigate = useNavigate()
  const {workerID, pageID} = useParams()
  const [certificates, setCertificates] = useState<CertificateModel[]>()
  const [checkedState, setCheckedState] = useState(new Array(0).fill(false))
  const [worker, setWorker] = useState<
    WorkerModel | RegisterModel | undefined
  >()

  const handleOnChange = position => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item,
    )
    setCheckedState(updatedCheckedState)
  }

  function getCertificatesIds(checked): number[] {
    return checked.filter(item => {
      return item
    })
  }

  const saveCertificates = () => {
    const checked = checkedState.map((item, index) => {
      if (item && certificates) {
        return certificates[index].id
      }
      return null
    })

    const certificatesIds = getCertificatesIds(checked)

    if (certificatesIds.length > 0) {
      if (worker) {
        const id = workerID ? parseInt(workerID, 10) : 0
        if (pageID === 'pre') {
          new RegisterService('pre_register')
            .storeCertificates(id, certificatesIds)
            .then(response => {
              if (response.status) {
                navigate(`/finished`)
              }
            })
        } else {
          new WorkerService()
            .storeCertificates(id, certificatesIds)
            .then(response => {
              if (response.status) {
                navigate(`/finished`)
              }
            })
        }
      }
    } else toast.error('Selecione pelo menos um selo')
  }

  useEffect(() => {
    async function seals() {
      new CertificateService()
        .getAll()
        .then(response => {
          setCertificates(response.data)
          return response.data
        })
        .catch(error => {
          toast.warning(error.data.message)
          return false
        })
    }

    async function workerDataGet() {
      const id = workerID ? parseInt(workerID, 10) : 0
      new WorkerService()
        .getOne(id)
        .then(response => {
          if (response.status) {
            setWorker(response.data)
          }
          return response.data
        })
        .catch(error => {
          toast.warning(error.data.message)
          return false
        })
    }

    async function preRegisterDataGet() {
      new RegisterService('pre_register')
        .getOne(Number(workerID))
        .then(response => {
          if (response.status) {
            setWorker(response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    if (workerID !== '0') {
      if (worker === undefined && certificates === undefined) {
        if (pageID === 'pre') preRegisterDataGet()
        else workerDataGet()
        seals()
      }
    } else if (certificates === undefined) seals()

    if (checkedState.length === 0) {
      if (certificates !== undefined && worker !== undefined) {
        const workerCertificates = new Array(certificates?.length).fill(false)
        if (certificates) {
          certificates.map((item, index) => {
            workerCertificates[index] =
              worker?.certificates.findIndex(x => x.id === item.id) !== -1
            return index
          })
          setCheckedState(workerCertificates)
        }
      }
    }
  })

  return (
    <div className={styles.certificates}>
      <h1>Selecione selos para o aluno - {worker ? worker.name : ''}</h1>
      <div className={styles.list}>
        {certificates
          ? certificates.map((val, index) => {
              return (
                <div
                  className={
                    checkedState[index]
                      ? styles.certificateSelected
                      : styles.certificate
                  }
                  role="button"
                  tabIndex={val.id}
                  onKeyDown={() => handleOnChange(index)}
                  onClick={() => handleOnChange(index)}
                  key={val.id}
                >
                  <img src={val.image} alt={val.name} />
                  <p>{val.name}</p>
                </div>
              )
            })
          : null}
      </div>
      <div className={styles.center}>
        <Button
          label="Continuar"
          id="search"
          type="button"
          click={saveCertificates}
        />
      </div>
    </div>
  )
}

export default Certificates
