import {AxiosRequestConfig, AxiosResponse, AxiosError} from 'axios'
import {toast} from 'react-toastify'
import ConnectionProvider from './ConnectionProvider'
import IServiceResponse from '../interface/IServiceResponse'

export default class BaseService<T> extends ConnectionProvider {
  protected endpointName: string

  constructor(
    endpointName: string,
    requestInterceptor = (config: AxiosRequestConfig) => config,
    reponseInterceptor = (config: AxiosResponse) => config,
  ) {
    super('/api/v1', requestInterceptor, reponseInterceptor)
    this.endpointName = endpointName
  }

  isLogged(status) {
    if (status === 401) {
      toast.warning('Sua sessao expirou!')
      setTimeout(() => {
        window.location.href = `/login`
      }, 500)
    }
  }

  public async getAll(): Promise<IServiceResponse<T[]>> {
    try {
      const response = await this.connection.get<T[]>(this.endpointName)

      return {
        status: true,
        data: response.data,
        response,
      }
    } catch (error) {
      const axiosError = error as AxiosError
      const errorResponse = {
        response: axiosError.response,
        data: axiosError.response?.data,
        status: false,
      }
      this.isLogged(axiosError.response?.data.status)
      throw errorResponse
    }
  }

  public async getOne(id?: number): Promise<IServiceResponse<T>> {
    try {
      const url = id ? `${this.endpointName}/${id}` : this.endpointName
      const response = await this.connection.get<T>(url)
      return {
        status: true,
        data: response.data,
        response,
      }
    } catch (error) {
      const axiosError = error as AxiosError
      const errorResponse = {
        response: axiosError.response,
        data: axiosError.response?.data,
        status: false,
      }

      this.isLogged(axiosError.response?.status)
      if (!axiosError.response) {
        window.location.href = `/login`
      }
      throw errorResponse
    }
  }

  public async search(param?: string): Promise<IServiceResponse<T>> {
    try {
      const response = await this.connection.post<T>(this.endpointName, {
        phone_number: param,
      })

      return {
        status: true,
        data: response.data,
        response,
      }
    } catch (error) {
      const axiosError = error as AxiosError
      const errorResponse = {
        response: axiosError.response,
        data: axiosError.response?.data,
        status: false,
      }
      this.isLogged(axiosError.response?.status)
      throw errorResponse
    }
  }

  public async listAll(
    page: number,
    perPage = 10,
    search: T[] = [],
    sort: string = 'ASC',
  ): Promise<IServiceResponse<any[]>> {
    try {
      const response = await this.connection.post<T[]>(
        `${this.endpointName}?per_page=${perPage}&page=${page}`,
        {...search, sort},
      )

      return {
        status: true,
        data: response.data,
        response,
      }
    } catch (error) {
      const axiosError = error as AxiosError
      const errorResponse = {
        response: axiosError.response,
        data: axiosError.response?.data,
        status: false,
      }
      this.isLogged(axiosError.response?.status)
      throw errorResponse
    }
  }
}
