import React from 'react'
import styles from './style.module.scss'

type BadgeProps = {
  title: string
  color: string
}

function Badge({title, color}: BadgeProps) {
  return (
    <div
      className={color === 'success' ? styles.badgeSuccess : styles.badgeError}
    >
      {title}
    </div>
  )
}

export default Badge
